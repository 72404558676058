import React, { Component } from "react";
import { css } from '@emotion/css'
import { BrowserStorage } from "../../BrowserStorage";
// Services
import i18n from "../../i18n";

// Lib
import SwipeableViews from 'react-swipeable-views';
import parse from 'html-react-parser';

class Onboarding extends Component {
  constructor(props) {
    super(props);
    
    const isOSDevice = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    
    this.state = {
      tabIndex: 0,
      modalContent: [],
      languages: [],
      selectedLanguage: "",
      isOSDevice,
      style: {},
      animateHeight: false
    }
  }

  componentDidMount() {
    const language = BrowserStorage.get("i18nextLng");
    let style;
    if (window.config.themeColor) {
      let {themeColor} = window.config;
      style = css`
        .backgroundPrimaryColor {
          background-color: ${themeColor || "#0D83DD"} !important;
        }
        .textPrimaryColor {
          color: ${themeColor || "#0D83DD"} !important;      
        }
      `
    }
    this.setState({ selectedLanguage: language, style});

    i18n.changeLanguage(language);
    this.handleModalContent();
  }

  handleModalContent = () => {
    const modalContent = i18n.t("modalContent", {returnObjects: true});
    
    const languages = [
      i18n.t("onboarding_modal__en"),
      i18n.t("onboarding_modal__fr")
    ];

    this.setState({
      modalContent,
      languages
    });
  }

  renderModalContent = () => {
    const { isOSDevice, modalContent } = this.state;

    let classnames = "";

    if (isOSDevice === true)
      classnames = 'ios-panel panel';
    else
      classnames = 'panel';

    if (window.config.breakWords === false) {
      classnames += " dont-break-words"
    }
    else {
      classnames += " break-words"
    }

    if (modalContent instanceof Array && modalContent.length > 0) {
      return modalContent.map((panel, i) => (
        <div className={classnames} key={i}>
          {
            panel.panelElements.map((item, i) => (
              <React.Fragment key={i}>
                {parse(item)}
              </React.Fragment>
            ))
          }
        </div>
      ))
    } else {
      return <></>;
    }
  
  }

  handleChangeTabIndex = (tabIndex) => {
    window.scrollTo(0,0);

    this.setState({ tabIndex, animateHeight: true });
  }

  nextPage = () => {
    const { tabIndex, modalContent } = this.state;
    
    if (modalContent.length > (tabIndex + 1)) {
      // Scroll Top on slide switch
      window.scrollTo(0,0);
  
      this.setState({ tabIndex: tabIndex + 1 });
    }
  }

  previousPage = () => {
    const { tabIndex } = this.state;

    if (tabIndex > 0) { 
      // Scroll Top on slide switch
      window.scrollTo(0,0);
  
      this.setState({ tabIndex: tabIndex - 1 });
    }
  }

  renderPaginationDots = (modalContent) => {
    const { tabIndex } = this.state;

    let renderDots = [];
  
    for (let i = 1; i <= modalContent.length; i++) {
      renderDots.push(<div key={i} className={(tabIndex + 1) === i ? "current-dot dot backgroundPrimaryColor" : "dot"} />)
    }

    return renderDots;
  }

  handleSetupAccountAction = () => {
    console.log('hi')
    // ToApp is a custom defined Interface, which also defined in the Native app
    if (window.webkit) {
      window.webkit.messageHandlers.ToApp.postMessage("WebPageCallbackOpenWallet");
    } else {
      window.Android.openWallet()
    }
  }

  onClickAction = () => this.handleSetupAccountAction()

  handleCloseModal = () => {
     // ToApp is a custom defined Interface, which also defined in the Native app
    if (window.webkit) {
      window.webkit.messageHandlers.ToApp.postMessage("WebPageCallbackClose");
    } else {
      window.Android.closeDialog()
    }
  }

  render() {
    const {
      tabIndex,
      modalContent,
      style,
      animateHeight
    } = this.state;

    return (
      <div className={"modal-content " + (window.webkit ? 'ios' : '') + " " + style}>
        <div className="scrollable">
          <div className={"header"}>
          </div>

          <SwipeableViews
            index={tabIndex}
            onChangeIndex={this.handleChangeTabIndex}
            className="swipeable"
            animateHeight={animateHeight}
          >
            {
              this.renderModalContent()
            }
          </SwipeableViews>
        </div>
        
        <div className="bottom-content">
          {/* BUTTONS GROUP */}

          <div className={"btn-group"}>
            {/* Next and Skip Button */}
            {
              (modalContent.length > 1 && modalContent[tabIndex + 1])
              &&
              <React.Fragment>
                <button 
                  className="btn-fill backgroundPrimaryColor"
                  onClick={() => this.handleChangeTabIndex(tabIndex + 1)}
                >
                  {i18n.t("onboarding_modal__next_btn")}
                </button>

                <button 
                  className="previous-btn textPrimaryColor"
                  onClick={this.handleCloseModal}
                >
                  {i18n.t("onboarding_modal__skip_btn")}
                </button>
              </React.Fragment>
            }

            {/* Get Started and i'll do it later btn */}
            {
              console.log(modalContent.length, tabIndex + 1)
            }
            {
              (modalContent.length === (tabIndex + 1))
              && 
              <React.Fragment>
                <button 
                  className="btn-fill backgroundPrimaryColor"
                  onClick={this.onClickAction}
                >
                  {i18n.t("onboarding_modal__set_up")}
                </button>

                <button 
                  className="close-btn textPrimaryColor"
                  onClick={this.handleCloseModal}
                >
                  {i18n.t("onboarding_modal__later_btn")}
                </button>
              </React.Fragment>
            }
          </div>

          <div className="dots">
            {
              modalContent.length > 1 && this.renderPaginationDots(modalContent)
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Onboarding;
