import React, { Component } from "react";

// Lib
import { HashRouter as Router, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from 'axios';

// Routes
import { PublicRoute } from "./PublicRoute";

// Components
import Onboarding from "./components/Onboarding/Onboarding";

// Utils
import i18n from './i18n';

// Css
import "./styles/App.css";

export default class App extends Component {
  state = {
    ready: false
  }

  componentDidMount() {
    let that = this;
    let startup = [];

    startup.push(new Promise((resolve, reject) => {
      axios.get("./config/config.json").then(function (res) {
        window.config = res.data;
        resolve();
      });
    }));
   
    startup.push(new Promise((resolve, reject) => {
      i18n.on('initialized', function() { 
        resolve();
      });
    }));
   
    return Promise.all(startup).then(function() {
      that.setState({ 
        ready: true,
      });
    })
  }

  render() {
    const { ready } = this.state;

    if (!ready) {
      return "";
    }

    const titleText = i18n.t("title");
    const noscriptText = i18n.t("noscript");

    return (
      <div className="App">
        <Helmet>
          <meta charset={i18n.t("charset")}/>
          <link href={i18n.t('favicon')} />
          <title>{titleText.toString()}</title>
          <noscript>{noscriptText.toString()}</noscript>
        </Helmet>

        <div className="app-wrapper">
          <Router>
            <main>
              <Switch>
                <PublicRoute component={Onboarding} />                
              </Switch>
            </main>
          </Router>
        </div>
      </div>
    );
  }
}
