import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import XHR from 'i18next-xhr-backend';

const detectionOptions = {
    order: ['querystring', 'navigator', 'htmlTag', 'cookie', 'localStorage', 'sessionStorage', 'path', 'subdomain'],
}

i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: './i18n/{{lng}}.json',
        },
        detection: detectionOptions,
        fallbackLng: "default",
        debug: false,

        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false
        },
        react: {
            wait: false
        }

    });

export default i18n;
